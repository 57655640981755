<template>
    <div class=" h-100">
        <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center">
            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
        </div>
        <div v-else class="start-overflow pt-0">
            <div class="create-company-container w-100 pt-0" v-if="permissionProduct">
                <div style="width:100%;" class="mb-5" >
                    <div class="page-layout carded left-sidebar"
                        style="border: 0px solid rgb(148, 190, 240) !important;border-radius: 0px !important;">
                        <div class="page-content-wrapper" style="overflow-x:auto">
                            <div class="page-content">
                                <div class="demo-content create-company-container w-100 pt-0">
                                    <div v-if="$route.path.startsWith('/employee/createemployee')" style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">Set Product Permissions</div>
                                    <div v-else style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">Subscribed Products</div>
                                    <table class="table table-new-template table-brand" style="border:1px solid grey">
                                        <thead class="text-left">
                                            <tr> 
                                                <th></th>
                                                <th>PRODUCTS</th>
                                                
                                                <th class="text-center">PERMISSION</th>
                                                <th >PRODUCT ACCESS</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr v-if="ajax_call_in_progress" >
                                                <td colspan="5" class="text-center">
                                                    <div class="d-flex align-items-center justify-content-center w-100">
                                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!ajax_call_in_progress && products_data.length == 0" class="text-center font-weight-bold">
                                                <td colspan="5" class="text-center">No Products Found</td>
                                            </tr>
                                            <tr v-else v-for="(product, index) in products_data" :key="index" class="sent-call-tr" >
                                                <template v-if="emp_data.user_access == 'owner'">
                                                    <td></td>
                                                    <td>{{product.product_name}}</td>
                                                    <td  > 
                                                        <div class="w-100" >
                                                            <div class="ml-auto mr-auto" style="position: relative; justify-content: left;width: 220px;">
                                                                <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                    <multiselect class="diginew-multiselect input-field-underline item" name="product_manager" :searchable="true"
                                                                        :options="purchase_manager_type" 
                                                                        :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')" 
                                                                        label="name" @open="getProductRoles(product.product_id, index)" @search-change="searchProductRoles" :loading="product.product_roles_loading"
                                                                        :show-labels="false" v-model="product.role_id"
                                                                        :options-limit="280" :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false"
                                                                    >
                                                                        <template slot="noOptions">
                                                                            <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                        </template>
                                                                        <template slot="noResult">
                                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                        </template>
                                                                        <!-- <template slot="afterList">
                                                                            <div v-observe-visibility="reachEndOfProductRoles" />
                                                                        </template> -->
                                                                    </multiselect>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </td>
                                                    <td >
                                                        <span class="switch switch-lg mr-2" style="top: 4px;">
                                                            <el-switch 
                                                                :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')"
                                                                active-color="#00448b"
                                                                inactive-color="#bcb8b8"
                                                                v-model ="product.has_access"
                                                                class="routers">
                                                            </el-switch> 
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </template>
                                                <template v-else-if="product.product_name != 'BoardX'">
                                                    <td></td>
                                                    <td>{{product.product_name}}</td>
                                                    <td> 
                                                        <div class="w-100" >
                                                            <div class="ml-auto mr-auto" style="position: relative; justify-content: left;width: 220px;">
                                                                <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                    <multiselect class="diginew-multiselect input-field-underline item" name="product_manager" :searchable="true"
                                                                        :options="purchase_manager_type" 
                                                                        :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')" 
                                                                        label="name" @open="getProductRoles(product.product_id, index, product.product_name)" @search-change="searchProductRoles" :loading="product.product_roles_loading"
                                                                        :show-labels="false" v-model="product.role_id"
                                                                        :options-limit="280" :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false"
                                                                    >
                                                                        <template slot="noOptions">
                                                                            <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                        </template>
                                                                        <template slot="noResult">
                                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                        </template>
                                                                        <!-- <template slot="afterList">
                                                                            <div v-observe-visibility="reachEndOfProductRoles" />
                                                                        </template> -->
                                                                    </multiselect>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </td>
                                                    <td >
                                                        <span class="switch switch-lg mr-2" style="top: 4px;">
                                                            <el-switch 
                                                                :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')"
                                                                active-color="#00448b"
                                                                inactive-color="#bcb8b8"
                                                                v-model ="product.has_access"
                                                                class="routers">
                                                            </el-switch> 
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <button class="btn btn-outline-secondary" :disabled="save_api_call_in_progress" style="width: 120px;border-radius: 5px;" @click="cancel" >Cancel</button>
                                            <button class="btn ml-3 text-white back-button" v-if="$route.path.startsWith('/employee/createemployee')" :disabled="save_api_call_in_progress" @click="backToScreen" >Back</button>
                                            <button class="btn btn-new-success ml-5" :disabled="save_api_call_in_progress" style="width: 120px;border-radius: 5px;" @click="save" >Save
                                                <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                            </button>
                                        </div>
                                    </div>
                                    <div v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <button class="btn btn-outline-secondary" :disabled="save_api_call_in_progress" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                                            <button class="btn ml-3 back-button" v-if="$route.path.startsWith('/employee/createemployee')" :disabled="save_api_call_in_progress" @click="backToScreen" >Back</button>
                                            <button class="btn btn-new-success ml-3" :disabled="save_api_call_in_progress" @click="save" style="width: 120px;border-radius: 5px;">Save
                                                <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" @hideSuccess="hideSuccess" :success_message="success_message" modal_name="create-success"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
    
</template>
<script>
import companies from '../mixins/companies';
const CreateSuccess = () => import('./CreateSuccess');
import { SweetModal } from 'sweet-modal-vue';
import {
    HalfCircleSpinner
} from "epic-spinners";
import product from '../mixins/product';
import globals from '../globals';
import axios from 'axios';
    export default {
        mixins: [companies, product],
        props: ["emp_data", "comp_data"],
        data() {
            return {
                save_api_call_in_progress: false,
                permissionProduct: true,
                warning_msg: '',
                success_msg: '',
                permissionProductTab: true,
                purchase_manager_type: [],
                purchase_manager:'',
                productAccess:true,
                productAccountAccess: false,
                skip: 0,
                limit: 10,
                search_key: '',
                products_data: [],
                product_roles_loading: false,
                ajax_call_in_progress: false,
                create_success: false,
                success_message: "",
                isVisible: false,
                count: 0,
                scrollable: false,
                product_id: '',
                product_index: 0,
                nameToRemove: []
            }
        },
        components: {
            HalfCircleSpinner,
            CreateSuccess,
            SweetModal
        },
        methods: {
            hideSuccess() {
                this.create_success = false;
                this.$router.push("/employee/listemployee/cmpid/"+this.$route.params.company_id);
            },
            async getSubscribedProducts() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        company_id: this.$route.params.company_id,
                        search_key: ''
                    };
                    let response = await this.getCompaniesSubscribedProducts(params);
                    if(response.status_id == 1) {
                        let prod_data = response.products_list;
                        prod_data.forEach((data, index) => {
                            this.products_data.push({
                                has_access: false,
                                product_id: data.id,
                                product_name: data.product_code,
                                role_id: {
                                    id: "",
                                    name: "",
                                },
                                product_roles_loading: false
                            })
                        })
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getProductRoles(id, index, product_name) {
                this.product_id = id;
                this.product_index = index;
                this.purchase_manager_type = [];
                this.products_data[this.product_index].product_roles_loading = true;
                try {
                    let params = {
                        skip: 0,
                        limit: 10,
                        search_key: this.search_key,
                        product_id: this.product_id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.purchase_manager_type = response.response;
                        if(product_name == 'DigiCloud' || product_name == 'Cloud'){
                            let nameToRemoveNew = []
                            this.purchase_manager_type.forEach(function(user, index){
                                if(user.name == 'Internal User'){
                                    nameToRemoveNew.push(user);
                                }
                            });
                            this.purchase_manager_type.forEach(function(user, index){
                                if(user.name == 'Admin'){
                                    nameToRemoveNew.push(user)
                                }
                            });
                            this.purchase_manager_type = nameToRemoveNew
                        }
                    }
                    this.count = response.response.length;
                    this.products_data[this.product_index].product_roles_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async searchProductRoles(search_key) {
                this.search_key = search_key;
                this.purchase_manager_type = [];
                this.products_data[this.product_index].product_roles_loading = true;
                try {
                    let params = {
                        skip: 0,
                        limit: 10,
                        search_key: this.search_key,
                        product_id: this.product_id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.purchase_manager_type = response.response;
                    }
                    this.count = response.response.length;
                    this.products_data[this.product_index].product_roles_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async reachEndOfProductRoles(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        this.products_data[this.product_index].product_roles_loading = true;
                        let params = {
                            skip: this.count,
                            limit: 10,
                            search_key: this.search_key,
                            product_id: this.product_id,
                        };
                        let response = await this.getListProductRole(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                tempFiles.forEach(el => {
                                    this.purchase_manager_type.push(el);
                                });
                                this.count += response.response.length;
                                this.products_data[this.product_index].product_roles_loading = false;
                            } else {
                                this.scrollable = true
                                this.products_data[this.product_index].product_roles_loading = false;
                            }
                        }
                        this.products_data[this.product_index].product_roles_loading = false;
                    }
                }
            },
            cancel() {
                this.$router.push('/employee/listemployee/cmpid/'+this.$route.params.company_id);
                // this.$emit('switchWizard1');
            },
            backToScreen() {
                this.$emit('switchWizard1');
            },
            async save() {
                this.save_api_call_in_progress = true
                try {
                    // this.ajax_call_in_progress = true;
                    if(this.$route.path.startsWith('/employee/viewemployee/cmpid/')) {
                        let params = {};
                        let is_permission_role = false
                        let current_product = ''
                        params.products = JSON.parse(JSON.stringify(this.products_data));
                        params.products.forEach((data, index) => {
                            if(data.id == '') {
                                delete data.id
                            }
                            if(data.hasOwnProperty('product_roles_loading')) {
                                delete data.product_roles_loading
                            }
                            //delete data.product_name
                            data.role_id = data.role_id.id
                            if(data.has_access) {
                                if(data.role_id == '' || data.role_id == null || data.role_id == undefined) {
                                    is_permission_role = true
                                    current_product = data.product_name
                                }
                            }
                        })
                        if(is_permission_role) {
                            this.ajax_call_in_progress = false;
                            this.warning_msg = 'Please select permission role for product '+current_product;
                            this.$refs.warning_modal.open();
                        } else {
                            await axios.patch(globals.AUTH_SERVICE + `/employees/${this.$route.params.id}`, params).then(response => {
                                if(response.data.status_id == 1) {
                                    this.success_msg = response.data.message
                                    this.$refs.success_modal.open();
                                    setTimeout(() => {
                                        this.$refs.success_modal.close();
                                        this.$emit("updateProductPermission");
                                    }, 2000);
                                }
                            }).catch((err) => {
                                this.ajax_call_in_progress = false;
                                this.warning_msg = err.response.data.reason;
                                this.$refs.warning_modal.open();
                            });
                        }
                    } else {
                        let params = JSON.parse(localStorage.getItem("employee_data"));
                        let is_permission_role = false
                        let current_product = ''
                        params.products = JSON.parse(JSON.stringify(this.products_data));
                        params.products.forEach((data, index) => {
                            if(data.id == '') {
                                delete data.id
                            }
                            if(data.hasOwnProperty('product_roles_loading')) {
                                delete data.product_roles_loading
                            }
                            delete data.product_name
                            data.role_id = data.role_id.id
                            if(data.has_access) {
                                if(data.role_id == '' || data.role_id == null || data.role_id == undefined) {
                                    is_permission_role = true
                                    current_product = data.product_name
                                }
                            }
                        })
                        if(is_permission_role) {
                            this.ajax_call_in_progress = false;
                            this.warning_msg = 'Please select permission role for product '+current_product;
                            this.$refs.warning_modal.open();
                        } else {
                            await axios.post(globals.AUTH_SERVICE + `/employees/`, params).then((response) => {
                                if(response.data.status_id == 1) {
                                    localStorage.removeItem("employee_data");
                                    this.success_message = response.data.message;
                                    this.save_api_call_in_progress = false
                                    this.create_success = true;
                                    setTimeout(() => {
                                        this.$modal.show('create-success');
                                    }, 500);
                                    this.save_api_call_in_progress = false
                                }
                            }).catch((err) => {
                                this.warning_msg = err.response.data.reason;
                                this.save_api_call_in_progress = false
                                this.$refs.warning_modal.open();
                            });
                        }
                    }
                }
                catch(err) {
                    reject(error.response.data.reason)
                }
            }
            
        },
        mounted() {
            if(this.$route.path.startsWith('/employee/viewemployee/cmpid/')) {
                let prod_data = this.emp_data.products;
                prod_data.forEach((data, index) => {
                    this.products_data.push({
                        has_access: data.has_access,
                        id: data.id ? data.id : "",
                        product_id: data.product_id,
                        product_name: data.product_name,
                        role_id: {
                            id: data.role_id,
                            name: data.role_name,
                        },
                        product_roles_loading: false
                    })
                })
            } else {
                this.getSubscribedProducts();
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        }
    }
</script>
<style >
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
        /*border: 1px solid grey;*/
    }
    .overflow-auto {
        overflow: auto;
    }
    .table-new-template>tbody>tr:nth-of-type(even) td {
        background-color: #dfe8f4;
        padding:10px;
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td {
        background-color: #fff;
        padding:10px;
        
    }
    
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags{
        background: #dfe8f4!important;
    }
    
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags{
        background: #fff!important;
    }
   
    .table-new-template thead tr th {
        vertical-align: middle;
        color: #005dae;
        text-transform: capitalize;
        font-weight: 600;
        letter-spacing: 1px;
        white-space: nowrap;
        font-size: 15px;
        text-align: left
    }

    .table-new-template thead th, 
    .table-new-template tbody td{
        align-items: center;
        padding: 10px !important;;

    }
    
    
    .table-new-template tbody tr,
    .table-new-template tbody td {
        align-items: center;
        font-weight: 600;
        text-align: left
    }
    .placeholder {
        color: #000!important;   
    }
    .table-new-template>tbody>tr> td div >.diginew-multiselect> .multiselect__tags > .multiselect__placeholder {
        color: #000;
    }
    .btn-new-cancel{
        border-radius: 5px!important;
        background-color: #d51650;
        padding-left: 25px !important;
        padding-right: 25px !important;
        color:#fff!important
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background-color:#fff
    }
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background: #dfe8f4!important;
    }
    .multiselect__input{
       margin-bottom :0px!important;
   }
   .multiselect__placeholder{
       padding:0px!important;
   }
   .back-button{
        background-color: #ff9a35 !important; 
        width: 120px;
        border-radius: 5px
   }
</style>